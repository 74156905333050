import { render, staticRenderFns } from "./QAModule.vue?vue&type=template&id=b9b9db0e&scoped=true&"
import script from "./QAModule.vue?vue&type=script&lang=js&"
export * from "./QAModule.vue?vue&type=script&lang=js&"
import style0 from "./QAModule.vue?vue&type=style&index=0&id=b9b9db0e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9b9db0e",
  null
  
)

export default component.exports