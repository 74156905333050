import axios from '../index';
// const { get, post, put, del } = axios;
const { post, put } = axios;
import config from '../config';
const { relApi } = config;

// 标签显示接口
export function getLabelList(data) {
  return post(`${relApi}/common/show_label_list/`, data);
}

// 创建新标签接口
export function createNewLabel(data) {
  return post(`${relApi}/common/create_label/`, data);
}

// 删除标签接口
export function deleteLabel(data) {
  return post(`${relApi}/common/delete_label/`, data);
}

// 知识库显示接口
export function showKnowledgeBase(data) {
  return post(`${relApi}/common/show_knowledge_base/`, data);
}

// 知识库详情显示接口
export function showKnowledgeBaseDetail(data) {
  return post(`${relApi}/common/show_knowledge_base_detail/`, data);
}

// 知识库编辑接口
export function editknowledgeBase(data) {
  return post(`${relApi}/common/edit_knowledge_base/`, data);
}

// 知识库新增接口
export function addKnowledgeBase(data) {
  return post(`${relApi}/common/add_knowledge_base/`, data);
}

// 知识库删除接口 
export function deleteKnowledgeBase(data) {
  return post(`${relApi}/common/delete_knowledge_base/`, data);
}

// 修改对话是否已保存到知识库
export function updateConversation(data) {
  return put(`${relApi}/common/updateConversation/${data.sentence_id}/`, data);
}
