<template>
  <div
    class="q-a-module"
    @click="toQADetail"
  >
    <div class="question">
      <img :src="require('@/assets/images/knowledge_icon_Q.png')">
      <h3 v-if="this.$route.path.slice(0,20)!='/knowledge-base/edit'">{{obj.question}}</h3>
      <van-field
        v-if="this.$route.path.slice(0,20)=='/knowledge-base/edit'"
        v-model="obj.question"
        rows="1"
        autosize
        type="textarea"
        placeholder="请输入问题"
        class="edit-qa-input"
        maxlength='500'
      />
    </div>
    <div class="answer">
      <img :src="require('@/assets/images/knowledge_icon_A.png')">
      <p
        v-if="this.$route.path.slice(0,20)!='/knowledge-base/edit'"
        v-html="obj.answer"
      ></p>
      <van-field
        id="symptomTxt"
        οninput="autoTextAreaHeight(this)"
        v-if="this.$route.path.slice(0,20)=='/knowledge-base/edit'"
        v-model="obj.answer"
        autosize
        type="textarea"
        placeholder="请输入答案"
        class="edit-qa-input"
        maxlength='500'
        :style="{height:`${eleHeight}`}"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      eleHeight: "",
    };
  },
  mounted() {
    let ele = document.getElementById("symptomTxt");
    if (ele) {
      this.autoTextAreaHeight(ele);
    }
  },
  methods: {
    autoTextAreaHeight(o) {
      this.eleHeight = o.scrollTop + o.scrollHeight;
    },
    toQADetail() {
      this.$emit("toQADetail", this.obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.q-a-module {
  width: 92%;
  height: auto;
  background: #ffffff;
  box-shadow: 0rem 0.03rem 0.53rem 0rem rgba(0, 0, 0, 0.06);
  border-radius: 0.27rem;
  margin: 0 auto;
  margin-bottom: 0.4rem;
  margin-top: 0.2rem;
  padding: 0.5rem;
  img {
    width: 0.64rem;
    height: 0.61rem;
    margin-right: 0.27rem;
    flex: none;
  }
  .question {
    display: flex;
    h3 {
      font-size: 0.45rem;
      font-weight: 500;
      color: #4a4a4a;
      line-height: 0.64rem;
      word-break: break-all;
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .answer {
    display: flex;
    margin-top: 0.5rem;
    p {
      font-size: 0.43rem;
      font-weight: 400;
      color: #444444;
      line-height: 0.67rem;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .van-field__body::-webkit-scrollbar {
      display: none;
    }
    .van-field__control::-webkit-scrollbar {
      display: none;
    }
  }
  /deep/ .van-field__control {
    height: 100% !important;
  }
  /deep/ .van-field__control::-webkit-scrollbar {
    display: none;
  }
  .edit-qa-input {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0.27rem;
    border: 0.03rem solid #eeeeee;
  }
}
</style>
