<template>
  <div
    class="knowledge-base-detail"
    v-if="DetailData"
  >
    <heads
      title="收藏夹"
      page="knowledgeDetail"
      @onClickRight="onClickRight"
    ></heads>

    <van-popup
      v-model="visible"
      class="prompt-box"
      :closeable="true"
      @close="visible=false"
      :round="true"
      :close-on-click-overlay="false"
    >
      <p class="reminder">提醒</p>
      <h3>确定要删除这条问答吗</h3>
      <div class="bottom">
        <van-button
          class="sure"
          @click="deleteDetail"
        >确认</van-button>
        <van-button
          class="cancel"
          @click="visible=false"
        >取消</van-button>
      </div>
    </van-popup>

    <!-- 时间&QA详情 start -->
    <p class="time">{{DetailData.update_time}}</p>
    <q-a-module :obj="DetailData"></q-a-module>
    <!-- 时间&QA详情 end -->

    <!-- 知识库标签 start -->
    <h3
      class="knowledge-tag"
      v-if="DetailData.parent_label || DetailData.label"
    >标签</h3>
    <!-- <Tag
      :title="DetailData.parent_label"
      v-if="DetailData.parent_label"
    /> -->
    <Tag
      :title="DetailData.label"
      v-if="DetailData.label"
    />
    <!-- 知识库标签 end -->

    <!-- 编辑按钮 start -->
    <br>
    <footer>
      <van-button
        class="edit-btn"
        :icon="require('@/assets/images/common_icon_edit.png')"
        @click="goEdit"
      >
        编辑
      </van-button>
    </footer>
    <!-- 编辑按钮 end -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import QAModule from "./Components/QAModule.vue";
import Tag from "./Components/Tag.vue";
import {
  deleteKnowledgeBase,
  showKnowledgeBaseDetail,
} from "@/fetch/api/knowdgebase.js";
import { mapState } from "vuex";
export default {
  components: {
    heads: Header,
    Tag,
    QAModule,
  },
  computed: {
    ...mapState({
      questionList: (state) => state.knowdgeBase.listData,
    }),
  },
  data() {
    return {
      id: this.$route.params.id,
      DetailData: null,
      visible: false,
    };
  },
  methods: {
    onClickRight() {
      this.visible = true;
    },
    goEdit() {
      this.$router.push(`/knowledge-base/edit/${this.id}?type=edit`);
    },
    deleteDetail() {
      this.visible = false;
      deleteKnowledgeBase({
        online_data_id: this.id,
        broker_id: this.broker_id,
      }).then((res) => {
        if (res.code == 200) {
          this.$toast("删除成功");
          this.$router.back();
        } else {
          this.$toast("删除失败，请稍后重试");
        }
      });
    },
    getKnowledgeBaseDetail() {
      showKnowledgeBaseDetail({
        online_data_id: this.id,
      }).then((res) => {
        if (res.code == 200) {
          this.DetailData = res.result;
        }
      });
    },
  },
  created() {
    this.getKnowledgeBaseDetail();
  },
};
</script>

<style lang="scss" scope>
.knowledge-base-detail {
  .header-nav .van-nav-bar__right {
    font-size: 0.32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ed5c5c;
    line-height: 0.45rem;
    img {
      width: 0.35rem;
      height: 0.35rem;
      margin-right: 0.03rem;
    }
  }
  .prompt-box {
    h3 {
      margin-top: 0.51rem;
    }
  }
  .time {
    font-size: 0.32rem;
    font-weight: 400;
    color: #999999;
    line-height: 0.45rem;
    text-align: center;
    padding-top: 0.56rem;
    padding-bottom: 0.27rem;
  }
  .knowledge-tag {
    width: 92%;
    font-size: 0.43rem;
    font-weight: 500;
    color: #333333;
    line-height: 0.59rem;
    margin: 0 auto;
    margin-top: 0.53rem;
    margin-bottom: 0.4rem;
  }
  .tags {
    background: #dde6f9;
    border-radius: 0.4rem;
    color: #4e7cde;
    border: 1px solid #dde6f9;
  }
  footer {
    width: 100%;
    text-align: center;
    margin-top: 2.13rem;
    .edit-btn {
      width: 66.93%;
      height: 0.96rem;
      border-radius: 0.48rem;
      opacity: 0.8;
      border: 0.03rem solid #5083f0;
      color: #5083f0;
      font-size: 0.4rem;
      font-weight: 400;
      line-height: 0.53rem;
      margin: 0 auto;
      // margin-bottom: 1.07rem;
      .van-icon__image {
        width: 0.43rem;
        height: 0.43rem;
      }
    }
  }
}
</style>
