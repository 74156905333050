<template>
  <span
    v-if="title"
    @click="changeActive()"
    :class="`${active2Tag?'active':''} tags`"
  >
    {{title}}
    <img
      v-if="type=='set'&&obj.official!=1"
      :src="require('@/assets/images/common_icon_del_12.png')"
      class="delete"
      @click="deleteTag(obj)"
    />
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    active2Tag: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "default",
    },
    obj: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    changeActive() {
      this.$emit("changeActive", this.obj);
    },
    deleteTag(obj) {
      this.$emit("deleteTag", obj);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.tags {
  position: relative;
  display: inline-block;
  // min-width: 2.13rem;
  padding: 0rem 0.32rem;
  height: 0.72rem;
  background: #ffffff;
  border-radius: 0.35rem;
  line-height: 0.72rem;
  margin-left: 0.4rem;
  text-align: center;
  line-height: 0.72rem;
  white-space: nowrap;
  border: 0.03rem solid #fff;
  &.active {
    background: #c7d7f9 !important;
    border-radius: 0.35rem !important;
    border: 0.03rem solid rgba(78, 124, 222, 0.6) !important;
    color: #346eea !important;
  }
  .delete {
    position: absolute;
    top: -0.05rem;
    right: -0.05rem;
    width: 0.32rem;
    height: 0.32rem;
  }
}
</style>
